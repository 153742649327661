import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { indigo, teal} from '@material-ui/core/colors';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: indigo[300],
    },
    secondary: {
      main: teal[300],
    },
    error: {
      main: red.A400,
    },
    background: {
      // default: '#EEE',
    },
  },
});

export default theme;
